import { Component, OnInit } from '@angular/core';
import { NewsComponent } from '../news/news.component';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
   
  constructor() { }

  ngOnInit(): void {
  }



}
