import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  news =[
    { title: "Fireless Cooking Competition", newsId: 'firelessCooking' },
		{ title: "Kannada Rajyotsava", newsId: 'rajyotsava' },
		{ title: "Children's Day / Ethnic Day", newsId: 'ethnicDay' },
		{ title: "Robotics Workshop", newsId: 'roboWorkshop' },
		{ title: "Kargil Vijay Diwas Day", newsId: 'kargilVijayDiwasDay' },
]; 
  constructor() { }

  ngOnInit() {
 }
  onClick(newsid){
    }
  
}
