import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-physics',
  templateUrl: './physics.component.html',
  styleUrls: ['./physics.component.css']
})
export class PhysicsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
