import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { AcademicsComponent } from './academics/academics.component';
import { ContactComponent } from './contact/contact.component';
import { RouterModule, Routes, ActivatedRoute, ParamMap  } from '@angular/router';
import { CampusTourComponent } from './campus-tour/campus-tour.component';
import { NewsComponent } from './news/news.component';
import { CalendarOfEventsComponent } from './home/calendar-of-events/calendar-of-events.component';
import { CourseOfferedComponent } from './academics/course-offered/course-offered.component';
import { FacultyDetailsComponent } from './academics/faculty-details/faculty-details.component';
import { EligibilityAdmissionComponent } from './eligibility-admission/eligibility-admission.component';
import { NormsAdmissionComponent } from './norms-admission/norms-admission.component';
import { DocumentsAdmissionComponent } from './documents-admission/documents-admission.component';
import { EligibilityPucExamComponent } from './eligibility-puc-exam/eligibility-puc-exam.component';
import { PhysicsComponent } from './physics/physics.component';
import { ChemistryComponent } from './chemistry/chemistry.component';
import { MathsComponent } from './maths/maths.component';
import { BioComponent } from './bio/bio.component';
import { ElectronicsComponent } from './electronics/electronics.component';
import { LanguageComponent } from './language/language.component';
import { RulesComponent } from './rules/rules.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { TestsComponent } from './tests/tests.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { CodeOfConductComponent } from './code-of-conduct/code-of-conduct.component';
import { AchievementsComponent } from './achievements/achievements.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'home',   component:HomeComponent },
  { path: 'about', component:AboutComponent },
  { path: 'academics', component:AcademicsComponent },
  {path:'academics/courseoffered',component:CourseOfferedComponent},
  {path:'academics/facultydetails',component:FacultyDetailsComponent},
  { path: 'contact', component:ContactComponent }, 
  { path: 'campusTour', component:CampusTourComponent },
  { path: 'courseoffered', component:CourseOfferedComponent},
  { path: 'faculty-details', component:FacultyDetailsComponent},
  { path: 'eligibilityadmission', component:EligibilityAdmissionComponent},
  { path: 'physics', component:PhysicsComponent },
  { path: 'chemistry', component:ChemistryComponent},
  { path: 'maths', component:MathsComponent },
  { path: 'normsadmission', component:NormsAdmissionComponent},
  { path: 'bio', component:BioComponent },
  { path: 'electronics', component:ElectronicsComponent },
  { path: 'language', component:LanguageComponent},
  { path: 'rules', component:RulesComponent },
  { path: 'attendance',   component:AttendanceComponent },
  { path: 'tests',   component:TestsComponent },
  { path: 'guidelines',   component:GuidelinesComponent },
  { path: 'codeofconduct',   component:CodeOfConductComponent },
  { path: 'achievements', component: AchievementsComponent},
  { path: 'eligibilitypucexam', component:EligibilityPucExamComponent},
  { path: 'documentsadmission', component:DocumentsAdmissionComponent},
  { path: 'news', component:NewsComponent},
  {path:'news/:newsId',component:NewsComponent},
];
@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HeaderComponent,
    HomeComponent,
    AcademicsComponent,
    ContactComponent,
    CampusTourComponent,
    NewsComponent,
    CalendarOfEventsComponent,
    CourseOfferedComponent,
    FacultyDetailsComponent,
    EligibilityAdmissionComponent,
    NormsAdmissionComponent,
    DocumentsAdmissionComponent,
    EligibilityPucExamComponent,
    PhysicsComponent,
    ChemistryComponent,
    MathsComponent,
    BioComponent,
    ElectronicsComponent,
    LanguageComponent,
    RulesComponent,
    AttendanceComponent,
    TestsComponent,
    GuidelinesComponent,
    CodeOfConductComponent,
    AchievementsComponent,
   
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
