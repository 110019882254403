import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-of-events',
  templateUrl: './calendar-of-events.component.html',
  styleUrls: ['./calendar-of-events.component.css']
})
export class CalendarOfEventsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
