import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-norms-admission',
  templateUrl: './norms-admission.component.html',
  styleUrls: ['./norms-admission.component.css']
})
export class NormsAdmissionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
